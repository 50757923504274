import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { BsFileEarmarkRichtext, BsChevronLeft, BsFileEarmarkRuled } from "react-icons/bs";
import slugify from "slugify";
import { FiCopy } from "react-icons/fi";

import api from "../../services/api";
import { Loader } from "../../components/loader";
import ActionPlanSection from "./components/action-plan";
import { exportPdf } from "./utils/pdf";
import { exportExcel } from "./utils/excel";
import Logo from "../../assets/img/logo-bleu.png";
import { Pie } from "../../components/charts";
import { capitalizeFirstLetter } from "../../utils";
import useStore from "Stores/zustand";

import { toast } from "react-toastify";
import { BILAN_TYPES, PROJECT_LABELS } from "./utils/static";
import { getAmount, getCO2Equivalence, getImpact, getUnit } from "./utils/bilan";
import { RECOMMENDATIONS } from "./utils/static";

//Modals
import ModalCompensation from "components/results/ModalCompensation";

const Result = () => {
  const { id } = useParams();
  const [bilan, setBilan] = useState();
  const [project, setProject] = useState();
  const [exportingPdf, setExportingPdf] = useState(false);
  const [isCompensationOpen , setIsCompensationOpen ] = useState(false);
  const [exportingExcel, setExportingExcel] = useState(false);
  const impact = bilan ? getImpact(bilan.matrice) : 0;
  const impactUnit = getUnit(impact, ["kgCO2eq", "tCO2eq"]);

  const { user } = useStore();

  useEffect(() => {
    api.get(`/bilan/${id}`).then((res) => {
      if (res.ok) {
        const newBilan = { ...res.data.bilan };
        newBilan.matrice = newBilan.matrice.sort((categoryA, categoryB) => {
          if (!categoryA?.code) return 1;
          if (!categoryB?.code) return -1;
          return categoryA?.code - categoryB?.code;
        });
        setBilan(newBilan);
        setProject(res.data.project);
      }
    });
  }, [id]);

  const handlePdfExport = async () => {
    setExportingPdf(true);
    try {
      await exportPdf(project, bilan);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue lors de l'export du PDF");
    }
    setExportingPdf(false);
  };

  const handleCncExport = async () => {
    setExportingExcel(true);
    try {
      await exportExcel(project, bilan);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue lors de l'export du fichier Excel");
    }
    setExportingExcel(false);
  };

  if (!bilan || !project) return <Loader />;
  return (
    <div className="h-full flex flex-col relative">
      <ModalCompensation isOpen={isCompensationOpen} close={() => setIsCompensationOpen(false)} title={project.title} user={user} totalEmissions={impact} />
      <header className="absolute top-0 w-full bg-white h-16 px-12">
        <div id="header_estimation" className="grid grid-cols-3 items-center h-full">
          <Link to="/" className="text-blue-dark flex items-center">
            <BsChevronLeft className="mr-2" />
            <span>Retour au tableau de bord</span>
          </Link>
          <h1 className="font-bold text-xl justify-self-center">{project.title}</h1>
          <div />
        </div>
      </header>
      <div className="h-full grid grid-cols-4 pt-16">
        <div className="h-full overflow-y-scroll py-8 pl-8 pr-4">
          <nav className="flex flex-col gap-2">
            <button className="h-14 blue-button flex items-center justify-center" onClick={handlePdfExport} disabled={exportingPdf}>
              {exportingPdf ? (
                <Loader />
              ) : (
                <>
                  <BsFileEarmarkRichtext className="mr-2" />
                  Télécharger une synthèse
                </>
              )}
            </button>
            {project.certified && (
              <button className="h-14 orange-button flex items-center justify-center" onClick={handleCncExport} disabled={exportingExcel}>
                {exportingExcel ? (
                  <Loader />
                ) : (
                  <>
                    <BsFileEarmarkRuled className="mr-2" />
                    Exporter au format CNC
                  </>
                )}
              </button>
            )}
            <Link to={`/bilan/${bilan._id}`} className="h-14 empty-button flex items-center justify-center gap-2">
              Modifier les données
            </Link>
            <a href="#action-plan" className="h-14 orange-button p-2 items-center flex justify-center">
              Réduire mes émissions
            </a>
            <Nav bilan={bilan} />
          </nav>
        </div>
        <main className="h-full flex flex-col gap-8 py-8 pr-8 pl-4 overflow-y-scroll col-span-3">
          <section className="w-full p-8 bg-white rounded">
            <HeaderInfos bilan={bilan} project={project} />
          </section>
          <section className="w-full p-8 bg-white rounded">
            <div id="resultats-generaux">
              <GeneralResults bilan={bilan} project={project} />
            </div>
          </section>

          <section id="action-plan" className="w-full p-8 bg-white rounded">
            <ActionPlanSection bilan={bilan} openCompensation={() => setIsCompensationOpen(true)} />
          </section>

          {bilan.matrice.map((category, i) => (
            <section key={i} className="w-full p-8 bg-white rounded">
              <div id={slugify(category.name.toLowerCase())}>
                <CategoryResults category={category} index={i + 1} />
              </div>
            </section>
          ))}

          <section className="w-full p-8 bg-white rounded">
            <h2 className="font-bold text-3xl leading-6 text-blue-dark mb-4">Recommandations</h2>
            {RECOMMENDATIONS.map((category, i) => (
              <div key={i} className="mb-4">
                <h3 className="font-bold text-lg leading-6 pl-8 text-blue-dark mb-4">{category.category}</h3>
                {category.items.map((data, idx) => (
                  <article className="text-blue-dark mb-5" key={idx}>
                    <h4 className="font-bold text-sm">{data.action}</h4>
                    <p className="text-sm">{data.description}</p>
                    <p className="text-sm">{data.tips}</p>
                    {data.url !== "" && (
                      <a href={data.url} className="text-xs">
                        {data.url}
                      </a>
                    )}
                  </article>
                ))}
              </div>
            ))}
          </section>

          <section className="w-full p-8 bg-white rounded">
            <h2 className="font-bold text-3xl leading-6 text-blue-dark mb-4">Information complémentaires</h2>
            <div className="mb-4">
              <h3 className="font-bold text-lg leading-6 pl-8 text-blue-dark mb-4">Usages de la synthèse</h3>
              <p>Ces infographies mettent en image la répartition des émissions carbone détaillée par facteur d'émission.</p>
              <p>Elles permettent de visualiser simplement et rapidement l'origine des principales émission carbone.</p>
              <p>La synthèse sert à accompagner la mise en place d'une stratégie bas carbone en aidant à définir les points d'action prioritaires.</p>
            </div>
            <div className="mb-4">
              <h3 className="font-bold text-lg leading-6 pl-8 text-blue-dark mb-4">Compensation</h3>
              <p className="text-sm">
                L'impact carbone de la production de {project.title} est estimé à{" "}
                <b>
                  {impactUnit.number.toLocaleString("fr", { maximumFractionDigits: 2 })} {impactUnit.unit}
                </b>
              </p>
              <p className="text-sm">Contactez nous si vous souhaitez contribuer à la neutralité carbone via notre partenaire OCO.</p>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

const HeaderInfos = ({ bilan, project }) => (
  <div className="w-full flex flex-col items-center justify-center">
    <div className="mb-4 w-32 h-10 flex items-center justify-center">
      <img src={Logo} alt="Seco2" className="max-w-full max-h-full" />
    </div>

    <div className="mx-auto text-center">
      <h4 className="text-2xl text-blue-dark font-bold">{project.title}</h4>
      <p className="leading-6 font-semibold text-blue-dark mb-5">{`Bilan carbone ${BILAN_TYPES[bilan.type].toLowerCase()} généré le ${new Date(bilan.updatedAt).toLocaleDateString(
        "fr-FR",
      )}`}</p>
      <p className="w-9/12 mx-auto text-xs mb-5">
        Ce bilan carbone est réalisé à partir de vos données fournies associées aux facteurs d'émission de La Base Carbone®, base de données publiques administrée par l'ADEME.
      </p>
    </div>
    {bilan.uniqueId && (
      <>
        <div className="mx-auto text-center">
          <p className="font-bold text-xs leading-4">Méthode de calcul homologuée par le CNC</p>
        </div>
        <div className="flex items-center">
          <p className="text-sm text-center mr-2">Numéro d'identification CNC:</p>
          <button onClick={() => navigator.clipboard.writeText(bilan.uniqueId)} className="flex items-center pr-8">
            {bilan.uniqueId} <FiCopy className="ml-2" />
          </button>
        </div>
      </>
    )}
  </div>
);

const GeneralResults = ({ bilan, project }) => {
  const impact = getImpact(bilan.matrice);
  const amountUnit = getUnit(getAmount(bilan.matrice), ["€", "k€", "M€"]);
  const impactUnit = getUnit(impact, ["kgCO2eq", "tCO2eq"]);
  const equivalence = getCO2Equivalence(impact);
  const labels = PROJECT_LABELS[project.format] || PROJECT_LABELS["Long-Métrage"];

  const infos = [
    { label: "Titre", value: project.title },
    { label: "Type de bilan", value: BILAN_TYPES[bilan.type] },
    { label: "Type d'oeuvre", value: project.type },
    { label: "Genre", value: project.genre },
    { label: "Format", value: project.format },
    { label: "Nom production", value: project.productionName },
    { label: "Lieu(x) de tournage", value: project.shootingLocation },
    { label: "Début du tournage", value: project.shootingStart },
    { label: "Nombre de jours de tournage", value: project.shootingDays },
    { label: "Budget", value: project.budget },
    { label: "Responsable bilan carbone", value: project.referent },
  ];

  return (
    <div className="flex flex-col items-center">
      <div className="w-full flex items-center border-b border-blue-border pb-4 mb-6 gap-3">
        <p className="font-bold text-xl px-2 bg-back-light-blue">0.</p>
        <h2 className="font-bold text-xl">Informations générales du projet</h2>
      </div>
      {impact > 0 ? (
        <>
          <section className="grid grid-cols-4 items-center gap-5">
            <Badge icon={labels.icon} name={labels.description} description="Type de production" color="#cfdce3" />
            <Badge
              icon="co2"
              name={`${impactUnit.number.toLocaleString("fr", { maximumFractionDigits: 0 })} ${impactUnit.unit}`}
              description="Montant des émissions pour la catégorie"
              color="#cfdce3"
            />

            <Badge
              icon="budget"
              name={`${amountUnit.number.toLocaleString("fr", { maximumFractionDigits: 0 })} ${amountUnit.unit}`}
              description="Budget pris en compte pour la catégorie"
              color="#cfdce3"
            />

            <Badge
              icon="balance"
              name={`${equivalence.value.toLocaleString("fr", { maximumFractionDigits: 0 })} ${equivalence.unit}`}
              description={equivalence.description}
              color="#cfdce3"
            />
          </section>
          <div className="mt-5 w-full px-16 py-4 grid grid-cols-2">
            {infos
              .filter((info) => info.value !== undefined)
              .map((info, i) => {
                //conversion to locale date string / specially for filming starting date
                let displayValue = info.value;
                if (typeof displayValue === "string" && !isNaN(Date.parse(displayValue))) {
                  displayValue = new Date(displayValue).toLocaleDateString();
                }

                return (
                  <div key={i} className="w-full flex items-center justify-center text-normal gap-8 mb-4">
                    <p className="w-1/2 text-right font-normal">{info.label}</p>
                    <p className="w-1/2 text-left font-bold text-base capitalize">{displayValue}</p>
                  </div>
                );
              })}
          </div>
          <Chart categories={bilan.matrice} />
        </>
      ) : (
        <section className="w-full">
          <Badge icon="co2" name="0 kgCO2eq" description="Montant des émissions pour la catégorie" color="#cfdce3" />
        </section>
      )}
    </div>
  );
};

const CategoryResults = ({ category, index }) => {
  const impact = getImpact(category);
  const amountUnit = getUnit(getAmount(category), ["€", "k€", "M€"]);
  const impactUnit = getUnit(impact, ["kgCO2eq", "tCO2eq"]);
  const equivalence = getCO2Equivalence(impact);
  const getCode = (element) => {
    const code = element?.code;
    if (!code) return "";
    return element.code?.replace(/(.{1})/g, "$1.");
  };
  return (
    <>
      <div className="w-full flex items-center border-b border-blue-border pb-4 mb-6 gap-3">
        <p className="font-bold text-xl px-2 leading-7" style={{ backgroundColor: category.color }}>
          {getCode(category)}
        </p>
        <h2 className="font-bold text-xl leading-7">{category.name}</h2>
      </div>

      {impactUnit.number > 0 ? (
        <section className="grid grid-cols-3 items-center gap-5">
          <Badge
            icon="co2"
            name={`${impactUnit.number.toLocaleString("fr", { maximumFractionDigits: 0 }) || 0} ${impactUnit.unit}`}
            description="Montant des émissions pour la catégorie"
            color={category.color}
          />
          {amountUnit.number > 0 && (
            <Badge
              icon="budget"
              name={`${amountUnit.number.toLocaleString("fr", { maximumFractionDigits: 0 }) || 0} ${amountUnit.unit}`}
              description="Budget pris en compte pour la catégorie"
              color={category.color}
            />
          )}
          <Badge
            icon="balance"
            name={`${equivalence.value.toLocaleString("fr", { maximumFractionDigits: 0 })} ${equivalence.unit}`}
            description={equivalence.description}
            color={category.color}
          />
        </section>
      ) : (
        <section className="w-full">
          <Badge icon="co2" name="0 kgCO2eq" description="Montant des émissions pour la catégorie" color={category.color} />
        </section>
      )}
      {impact > 0 && category.categories && category.categories.length > 0 && <Chart categories={category.categories} />}
    </>
  );
};

const Badge = ({ icon, name, description, subdescription, color }) => {
  return (
    <div className="flex flex-col items-center p-4 h-full" style={{ backgroundColor: color }}>
      <div>
        <img src={`/images/icons/${icon}.svg`} alt={name} className="row-span-2 h-12" />
      </div>
      <p className="font-bold text-center py-2 text-lg">{name}</p>
      <p className="text-center">{description}</p>
      {subdescription && <span className="text-center text-xs leading-3 italic py-px px-3 mt-1">{subdescription}</span>}
    </div>
  );
};

const Chart = ({ categories }) => {
  const getCode = (element) => {
    const code = element?.code;
    if (!code) return "";
    return element.code?.replace(/(.{1})/g, "$1.");
  };

  const impactByCategory = categories.map((category) => ({
    impact: getImpact(category),
    name: capitalizeFirstLetter(category.name),
    color: category.color,
    code: category.code,
  }));

  const data = [];
  categories.forEach((c) => {
    const value = getImpact(c);
    data.push({ name: `${getCode(c)} ${capitalizeFirstLetter(c.name)}`, value, color: c.color, legend: `${value.toLocaleString("fr", { maximumFractionDigits: 0 })} kgCO2eq` });
  });

  return (
    <>
      <p className="font-bold text-base leading-5 w-full flex items-center border-b border-blue-border pb-4 my-6">Chiffres clés de votre bilan carbone</p>
      <p className="mb-5">Classement des catégories par niveau d'émissions, par ordre décroissant.</p>
      <div className="mb-4 flex">
        <div className="w-full grid grid-cols-2 gap-x-6">
          <div className="flex items-center mb-4">
            <h3 className="w-[60%] font-semibold">Catégorie</h3>
            <h3 className="flex-1 font-semibold text-right">Émissions</h3>
          </div>
          <div className="flex items-center mb-4">
            <h3 className="w-[60%] font-semibold">Catégorie</h3>
            <h3 className="flex-1 font-semibold text-right">Émissions</h3>
          </div>
          {impactByCategory
            .filter((category) => category.impact !== 0)
            .sort((a, b) => b.impact - a.impact)
            .map((category, i) => (
              <div className="flex items-center mb-3" key={i}>
                <div className="w-[60%] flex items-center">
                  <div className="w-4 h-4 mr-3" style={{ backgroundColor: category.color }} />
                  <span className="flex-1 font-normal text-sm truncate">
                    {getCode(category)}&nbsp;{category.name}
                  </span>
                </div>
                <span className="flex-1 font-normal text-sm text-right">{category.impact.toLocaleString("fr", { maximumFractionDigits: 2 })} kgCO2eq</span>
              </div>
            ))}
        </div>
      </div>

      <div className="mb-4 flex flex-col items-center">
        <p className="font-normal text-center text-base mb-4">Répartition des émissions par postes du devis</p>
        <div className="h-56 w-full">
          <Pie data={data} />
        </div>
      </div>

      <div className="grid grid-cols-4 items-center border border-blue-border p-4 gap-y-2 gap-x-4">
        {impactByCategory
          .filter((category) => category.impact !== 0)
          .map((category, i) => (
            <div key={i} className="flex items-center">
              <div className="w-4 h-4 rounded-sm mr-3" style={{ backgroundColor: category.color }} />
              <p className="font-normal text-xs">{category.name}</p>
            </div>
          ))}
      </div>
    </>
  );
};

const Nav = ({ bilan }) => {
  const location = useLocation();
  const [activeAnchor, setActiveAnchor] = useState(location.hash);

  const getCode = (element) => {
    const code = element?.code;
    if (!code) return "";
    return element.code?.replace(/(.{1})/g, "$1.");
  };

  useEffect(() => {
    setActiveAnchor(location.hash.slice(1));
  }, [location]);

  return (
    <div className="flex flex-col gap-3">
      <div className="bg-white rounded overflow-hidden">
        <p className="items-center h-8 text-base leading-8 pl-4 w-full font-bold bg-back-light-blue text-blue-dark">Accès rapide</p>
        <div className="flex flex-col px-3 divide-y divide-blue-border">
          <a href="#resultats-generaux" className="py-3 flex items-center hover:font-bold">
            <div className="w-[10%] mr-3">
              <p className="w-6 text-center bg-blue-gray rounded"></p>
            </div>
            <p>Résultats Généraux</p>
          </a>
          {bilan.matrice.map((category, i) => {
            const anchor = slugify(category.name.toLowerCase());
            return (
              <a key={i} href={`#${anchor}`} className="py-3 flex items-center hover:font-bold">
                <div
                  className={`w-[10%] mr-3 ${
                    activeAnchor === anchor ? "font-bold before:w-2 before:h-6 before:bg-blue-dark before:p-1 before:rounded-sm before:absolute before:ml-[-20px]" : ""
                  }`}>
                  <p style={{ background: category.color }} className="w-6 text-center rounded">
                    {getCode(category)}
                  </p>
                </div>
                <p className={`flex-1 truncate ${activeAnchor === anchor ? "font-bold" : ""}`}>{category.name}</p>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Result;
