import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import slugify from "slugify";

import Logo from "../../../assets/img/logo-bleu.png";
import { getImpact, getUnit } from "./bilan";
import { RECOMMENDATIONS } from "./static";

const LEFT_OFFSET = 15; // 15mm
const TOP_OFFSET = 20; // 15mm
const PAGE_WIDTH = 210 - LEFT_OFFSET * 2; // 180mm
const PAGE_HEIGHT = 297 - TOP_OFFSET * 2; // 257mm

const TAB_WIDTH = 10; // 10mm
const BODY = { fontSize: 10, fontStyle: "normal", fontWeight: "normal", lineHeigth: 1.15, color: "#000000", align: "left" };
const TITLE1 = { fontSize: 18, fontStyle: "normal", fontWeight: "bold", lineHeigth: 1.15, color: "#0c5371", align: "left" };
const TITLE2 = { fontSize: 14, fontStyle: "normal", fontWeight: "bold", lineHeigth: 1.15, color: "#0c5371", align: "left" };
const TITLE3 = { fontSize: 12, fontStyle: "normal", fontWeight: "bold", lineHeigth: 1.15, color: "#0c5371", align: "left" };

const TYPES = {
  forecast: "Prévisionnel",
  final: "Définitif",
};

const replaceVariables = (text, variables) => {
  return text.replace(/{(.*?)}/g, (match, key) => variables[key] || match);
}

const isThereAVariable = (text) => {
  const placeholderRegex = /\{[^}]+\}/;
  return placeholderRegex.test(text)
}

export const exportPdf = async (project, bilan) => {
  const impact = bilan ? getImpact(bilan.matrice) : 0;
  const impactUnit = getUnit(impact, ["kgCO2eq", "tCO2eq"]);
  const sections = ["resultats-generaux", ...bilan.matrice.map((c) => slugify(c.name.toLowerCase()))];
  const doc = new jsPDF("p", "mm", "a4");

  // Cover
  let offsetHeight = PAGE_HEIGHT / 3;
  const img = new Image();
  img.src = Logo; // Image 3508px x 1525px --> 60mm x 26mm
  doc.addImage(img, "PNG", PAGE_WIDTH / 2 + LEFT_OFFSET - 30, offsetHeight, 60, 26, "FAST");
  offsetHeight += 40;
  offsetHeight = addText(doc, project.title, PAGE_WIDTH / 2 + LEFT_OFFSET, offsetHeight, { ...TITLE1, align: "center" });
  offsetHeight = addText(
    doc,
    `Bilan carbone ${TYPES[bilan.type].toLowerCase()} généré le ${new Date(bilan.updatedAt).toLocaleDateString("fr-FR")}`,
    PAGE_WIDTH / 2 + LEFT_OFFSET,
    offsetHeight,
    {
      ...TITLE2,
      align: "center",
    },
  );
  offsetHeight = addText(
    doc,
    `Ce bilan carbone est réalisé à partir de vos données fournies associées aux facteurs d'émission de La Base Carbone®, base de données publiques administrée par l'ADEME.`,
    PAGE_WIDTH / 2 + LEFT_OFFSET,
    offsetHeight,
    { ...BODY, align: "center" },
  );
  if (bilan.uniqueId) {
    offsetHeight += 10;
    offsetHeight = addText(doc, `Méthode de calcul homologuée par le CNC`, PAGE_WIDTH / 2 + LEFT_OFFSET, offsetHeight, {
      ...BODY,
      fontWeight: "bold",
      align: "center",
    });
    offsetHeight = addText(doc, `Numéro d'identification CNC: ${bilan.uniqueId}`, PAGE_WIDTH / 2 + LEFT_OFFSET, offsetHeight, {
      ...BODY,
      align: "center",
    });
  }

  // Add Results TODO clean this to add real data in PDF and not just a screenshot
  for (let i = 0; i < sections.length; i++) {
    const content = document.getElementById(sections[i]);
    if (!content) continue;

    let elHeight = content.offsetHeight;
    let elWidth = content.offsetWidth;

    const ratio = Math.floor((PAGE_WIDTH / elWidth) * 100) / 100;
    //resize chart width and heigth proportionally
    elHeight = Math.floor(elHeight * ratio);
    elWidth = Math.floor(elWidth * ratio);

    doc.addPage();
    // You may adjust this value to control the quality and size of the captured content
    const canvas = await html2canvas(content, { scale: 2 });
    const img = canvas.toDataURL("image/jpeg");

    doc.addImage(img, "JPEG", LEFT_OFFSET, TOP_OFFSET, elWidth, elHeight, undefined, "FAST");
  }

  const variables = {
    projectName: project.title,
    projectImpact: `${impactUnit.number} ${impactUnit.unit}`
  }

  // Recommandations
  doc.addPage();
  offsetHeight = 20;
  offsetHeight = addText(doc, "Recommandations", LEFT_OFFSET, offsetHeight, TITLE1);
  RECOMMENDATIONS.forEach((recommandation) => {
    offsetHeight = addText(doc, recommandation.category, LEFT_OFFSET + TAB_WIDTH, offsetHeight, TITLE2);
    recommandation.items.forEach((item) => {
      if (item.description && isThereAVariable(item.description)) {
        item.description = replaceVariables(item.description, variables)
      }
      if (item.action) offsetHeight = addText(doc, item.action, LEFT_OFFSET + TAB_WIDTH * 2, offsetHeight, TITLE3);
      if (item.description) offsetHeight = addText(doc, item.description, LEFT_OFFSET, offsetHeight);
      if (item.tips) offsetHeight = addText(doc, item.tips, LEFT_OFFSET, offsetHeight);
      if (item.url) offsetHeight = addText(doc, item.url, LEFT_OFFSET, offsetHeight);
      offsetHeight += 5;
    });
    offsetHeight += 5;
  });

  // Additional infos
  offsetHeight = addText(doc, "Informations complémentaires", LEFT_OFFSET, offsetHeight, TITLE1);
  offsetHeight = addText(doc, "Usages de la synthèse", LEFT_OFFSET + TAB_WIDTH, offsetHeight, TITLE2);
  offsetHeight = addText(doc, "Ces infographies mettent en image la répartition des émissions carbone détaillée par facteur d'émission.", LEFT_OFFSET, offsetHeight);
  offsetHeight = addText(doc, "Elles permettent de visualiser simplement et rapidement l'origine des principales émission carbone.", LEFT_OFFSET, offsetHeight);
  offsetHeight = addText(
    doc,
    "La synthèse sert à accompagner la mise en place d'une stratégie bas carbone en aidant à définir les points d'action prioritaires.",
    LEFT_OFFSET,
    offsetHeight,
  );
  offsetHeight += 5;
  offsetHeight = addText(doc, "Compensation", LEFT_OFFSET + TAB_WIDTH, offsetHeight, TITLE2);
  offsetHeight = addText(
    doc,
    `L'impact carbone de la production de ${project.title} est estimé à ${impactUnit.number.toLocaleString("fr", { maximumFractionDigits: 2 })} ${impactUnit.unit}`,
    LEFT_OFFSET,
    offsetHeight,
  );
  offsetHeight = addText(doc, "Contactez nous si vous souhaitez contribuer à la neutralité carbone via notre partenaire OCO.", LEFT_OFFSET, offsetHeight);

  doc.save(`Seco2 Bilan ${project.title}`);
};

const addText = (doc, text, x, y, options = BODY, maxWidth = PAGE_WIDTH, maxHeight = PAGE_HEIGHT) => {
  doc.setFont("helvetica", options.fontStyle, options.fontWeight);
  doc.setFontSize(options.fontSize);
  doc.setTextColor(options.color);

  doc.splitTextToSize(text, maxWidth).forEach((line) => {
    if (y > maxHeight) {
      doc.addPage();
      y = 20;
    }
    doc.text(line, x, y, { align: options.align });
    y += options.fontSize / 2 + options.lineHeigth;
  });

  return y;
};
